import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import WorkflowCard from "./WorkflowCard";

export default function RedshiftFacebookConversionsWorkflow() {
    const data = useStaticQuery(graphql`
    query RedshiftFacebookConversionsWorkflowQuery {
      recipe(enabled: {eq: true}, slug: {eq: "redshift-warehouse-facebook-ads-offline-conversions"}) {
        content
        description
        name
        total_steps
        slug
        steps {
          object
        }
      }
    }
  `)

    const recipe = data.recipe;

    return (
        <WorkflowCard recipe={recipe} />
    )
}